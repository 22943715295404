// Dependency Imports
import PropTypes from 'prop-types';
import React from 'react';
import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import {Route, HashRouter as Router} from 'react-router-dom';

import * as action from '../src/redux/actions/actionTypes';
import * as LANG_SET from './translations/main';
import Login from './pages/login/login';
// Custom Imports
import {BaseRoutes} from './routes';
import './static/scss/common.scss';

function App(props) {
  const {lang} = props;
  let auth = false;

  if (window.location.pathname === '/') {
    if (auth) {
      window.location = '/dashbord/fanzly-analytics-overview';
    }
  }

  return (
    // eslint-disable-next-line import/namespace
    <IntlProvider locale={lang} key={lang} messages={LANG_SET[lang]}>
      <Router>
        {/* maping various routes inside router, from pre-defined routes array, all the components from here, will be  */}
        {BaseRoutes.map(({component: Cmp, ...route}, index) => (
          <Route
            key={'baseRoute' + index}
            {...route}
            render={(routeProps) => <Cmp {...props} {...routeProps} />}
          />
        ))}

        <Route path="/" exact component={Login}></Route>
      </Router>
    </IntlProvider>
  );
}

// Create validations for Props, required or type etc.
App.propTypes = {
  lang: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    reduxState: state,
    lang: state.lang,
  };
};

const dispatch = (dispatch) => {
  return {
    login: (flag) =>
      dispatch({
        action: action.ISLOGIN,
        payload: {
          isLogin: flag,
        },
      }),
  };
};

export default connect(mapStateToProps, dispatch)(App);
