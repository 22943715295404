let chartConfig = {
  series: [
    {
      data: [],
    },
  ],
  options: {
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
        autoSelected: 'zoom',
      },
    },
    dataLabels: {
      enabled: false,
      background: {
        enabled: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
    markers: {
      size: 0,
    },
    title: {
      text: '',
      align: 'left',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    noData: {
      text: 'Loading data...',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '12px',
      },
      xaxis: {
        show: false,
      },
      yaxis: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      title: {
        text: '',
      },
      style: {
        fontSize: '11px',
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
      categories: [],
      tooltip: {
        enabled: false,
      },

      labels: {
        style: {
          fontSize: '11px',
        },
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
  },
};

export {chartConfig};
