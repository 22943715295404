// Dependency Imports
import ls from 'local-storage';
import {locale} from 'moment';
import React from 'react';

import Img from '../../components/Image/Images';
// Custom Imports
import Input from '../../components/InputBox/Input';
import Button from '../../components/button/Button';
import {APP_LOGO, BACK_ARROW, MESSAGE} from '../../lib/config';
import {goToDashbord} from '../../lib/helper';
import {setCookie} from '../../lib/session';
import {emailValidator, requiredValidator} from '../../lib/validate';
import './login.scss';

const queryString = require('query-string');

class Login extends React.Component {
  state = {
    formField: {
      email: {
        label: 'Email',
        input: {
          name: 'email',
          type: 'email',
          value: '',
        },

        error: false,
        focus: false,
        errorMessage: 'Enter valid email',
      },
      password: {
        label: 'Password',
        input: {
          type: 'password',
          name: 'password',
          value: '',
        },
        error: false,
        focus: false,
        errorMessage: 'Enter valid password',
      },
    },
    validate: false,
    error: false,
    errorMessage: '',

    page: 0,
    mailIdToResetPassword: '',
    mailIdMessage: '',
    mailIdStatus: false,
  };

  mailIdToResetPasswordHandler = (e) => {
    let isEmailCorrect = emailValidator(e.target.value);

    if (isEmailCorrect) {
      this.setState({
        mailIdToResetPassword: e.target.value,
        mailIdMessage: '',
        mailIdStatus: true,
      });
    } else {
      this.setState({
        mailIdToResetPassword: e.target.value,
        mailIdMessage: 'Enter valid email',
        mailIdStatus: false,
      });
    }
  };

  pageHandler = (page) => {
    this.setState({page});
  };
  //on input change
  onInputChange(field, event) {
    let error = 1 !== requiredValidator(event.target);

    console.log(error);
    let stateObject = {...this.state};

    stateObject.formField[field]['error'] = error;

    stateObject.formField[field]['input']['value'] = event.target.value;
    stateObject.formField[field]['focus'] = true;
    let validate = true;

    Object.keys(stateObject.formField).forEach((item) => {
      if (!stateObject.formField[item].focus || stateObject.formField[item].error) {
        validate = false;
      }

      stateObject.validate = validate;
    });

    stateObject.error = false;
    this.setState(stateObject);
  }

  onResetClickHandler = async () => {
    try {
      console.log('password reset .....x');
      this.pageHandler(3);
    } catch (e) {
      this.setState({mailIdMessage: e.response.data.message});
      console.log(e, 'err\n\n', e.response, 'response');
    }
  };

  async componentDidMount() {
    try {
      // const search = `?storeId=0&refToken=00a7ac57-c1ee-1ab3-06dc-b2db01b9b72c&token=eyJhbGciOiJSU0EtT0FFUCIsImN0eSI6IkpXVCIsImVuYyI6IkExMjhHQ00iLCJ0eXAiOiJKV1QifQ.l-sDkVwkERZ2XPRdDJfCngIw2gyR83z675XPhwwYuRYa_oDeUcUrMgifhJvj5UMnLEgBcYA6wChDvaEsUJWpxBrwlmG4OkptmLjM31xZnk8cGu4wubUip2wkKNkOupIF2JLc8xJGbWqxQ7A34HzF9GFX1Ev2ZtHmNkxEBybAUcE.7jw6vek6vttqHnp6.1kC-odurF5WAXH-REAkD7AsdAKSva85b_w97IxR3DK2TSrh_CSL0Mj73PW-To0VXEetwwOyeqPajriqvjUsBH1ZbCEgHcgCKASLPd8UcS3gUnIwKVO9MaK06wv9ePl4_Q-I_cDZhPX5pSJRV3-B9L_9K6bhKMpD6pVSm7Kj9bgS7UVulWJHRSdPPuGKjab0Ez49ZWK1_QBDHG3iHC0WPSgvE8GTE2P4T853PwZndsuNfKhzX4b36Sxum-bGFDvnPuhRT437aKr-FIj_emVXa3Vi9GOEPh_thNDgZ0FimtsxYb2o2-S42fAxxIxjQqm2Sn_h7OebeK54AXJ3Ec9ylGe0-yIFEcLC25bgZpvWgqIg1QnaRpO89y-PUxi_RjXmF4bDAKtLDaDNaLkoVWPOihg9cXxygxtFLuoZBP4nu5-dpgIEuVtIepRfkTZH3E5ezmaZauMDmlYPT05KCw3UfaDaqNt2M6ALEAK5ZEpkV4MzOj5Lucvvl10-Pog5UkPUdCBBalwbiGn2RBp9BpqAhfSUc6_-5e-kxwfU0skuqUJjCAJLcn2D9WggVrP1IBJQ5s464dhHEnNpPK9S9gHqBAbns0JEM5S9_Ub_aUBV4eWjb9Hki2rUAUR17JMdTaqznRNXVrGYphlLE-h1Rx-EAMWej_1MWrI0j284u2Z6fQmZCuaaSwAD0NW87VV1p3PlgFfEMlPOWRg0zSClWqe-_2nz-FnvVmCJDmf3EiS7X0ED4P9HYNGqSaE08bYfkrMhKhWztSYfPQ7vIp0ZGXGmPgRbyPvDX1QXSg_BeGdw.Sw18fkuR66OSBFZ6KIWzJQ`
      const search = this.props.location.search; // could be '?token="eykj.lkajdfs.lkasdjfl"'
      const parsed = queryString.parse(search);

      const token = parsed.token;
      const storeid = parsed.storeId;
      const refToken = parsed.refToken;

      if (parsed && token.length) {
        ls.remove('store_id');
        ls.set('store_id', storeid);
        ls.remove('token');
        ls.set('token', token);
        ls.remove('refToken');
        ls.set('refreshToken', refToken);
        setCookie('token', token);
        setCookie('accessExpireAt', token);
        setCookie('refreshToken', refToken);
        this.props.history.push('/dashbord/fanzly-analytics-overview');
      }
    } catch (e) {
      console.log(e);
      this.setState({
        error: true,
        errorMessage: 'Login Error Please try again ',
      });
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        error: true,
        errorMessage: 'please refresh the page for login',
      });
      console.log('login data success', '');
    } catch (e) {
      console.log(e);
      this.setState({
        error: true,
        errorMessage: 'please refresh the page for login',
      });
      console.log(e.response, 'eresp', 'status ');
    }
  };

  static getDerivedStateFromProps(props) {
    goToDashbord(props.history);
  }

  render() {
    let formField = Object.keys(this.state.formField).map((itemIndex) => {
      return (
        <div className="my-2" key={itemIndex}>
          <div className="form-label">{this.state.formField[itemIndex]['label']}</div>
          <Input
            className="form-control"
            {...this.state.formField[itemIndex]['input']}
            onChange={this.onInputChange.bind(this, itemIndex)}
          ></Input>
          <div className={`error ${this.state.formField[itemIndex]['error'] ? 'show' : 'hide'}`}>
            {this.state.formField[itemIndex]['errorMessage']}
          </div>
        </div>
      );
    });

    return (
      <div className="login-form">
        <form>
          <div className="login-form-inner">
            {this.state.page === 0 ? (
              <div className="card animated fadeIn">
                <div className="logo">
                  <Img src={APP_LOGO} className="app-logo"></Img>
                  <span className="title">Data Analyst {locale.welcome}</span>
                </div>
                <div className="mt-3">{formField}</div>
                {this.state.error && (
                  <div
                    style={{
                      color: 'red',
                      textAlign: 'center',
                      marginTop: '-12px',
                      marginBottom: '10px',
                    }}
                  >
                    {this.state.errorMessage}
                  </div>
                )}
                <div className="forgotPassword" onClick={() => this.pageHandler(1)}>
                  Forgot Password?
                </div>
                <div className="text-center mb-4 mt-2">
                  <Button type="submit" onClick={this.onSubmit} disabled={!this.state.validate}>
                    Login
                  </Button>
                  <i className="fas fa-home"></i>
                </div>
              </div>
            ) : this.state.page === 1 ? (
              <div>
                <div className="forgotPassWrapper container animated fadeIn">
                  <div className="back">
                    <Img
                      src={BACK_ARROW}
                      className="backArrow	mt-3"
                      onClick={() => this.pageHandler(0)}
                    ></Img>
                  </div>
                  <div className="sub mt-5">What is your Mail Id?</div>
                  <div className="description">
                    Enter the registered email and we will send you the link.
                  </div>
                  <div className="email mt-3">Email</div>
                  <div className="mt-1">
                    <Input
                      className="form-control"
                      id="mailIdToResetPassword"
                      value={this.state.mailIdToResetPassword}
                      onChange={this.mailIdToResetPasswordHandler}
                    />
                  </div>
                  <div className="emailStatus mt-1">{true && this.state.mailIdMessage}</div>
                  <Button
                    onClick={this.onResetClickHandler}
                    disabled={!this.state.mailIdStatus}
                    classes="resetLink"
                  >
                    Send Reset Link
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div className="forgotPassWrapper container animated fadeIn">
                  <div className="description pt-5">
                    We have sent a password recovery link to your registered email address
                    <span className="emailId">{' ' + this.state.mailIdToResetPassword + '. '}</span>
                    Please check your inbox and follow the instructions on it to set a new password.
                  </div>
                  <div className="row mt-5">
                    <Img src={MESSAGE} height="170px" className="message" />
                  </div>
                  <Button onClick={() => this.pageHandler(0)} classes="resetLink">
                    Login
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
