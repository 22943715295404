// Dependency Imports
//icons
import PollIcon from '@material-ui/icons/Poll';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
//helper function
import {
  Route,
  withRouter, // BrowserRouter as Router,
  // Redirect,
} from 'react-router-dom';

import Header from '../../components/header/header';
// Custom Imports
import '../../static/scss/App.scss';
//nested routes
import Dashboard from './Dashboard/index';
import TopCreators from './FanzlyAnalytics/TopCreators';
import TopNPost from './FanzlyAnalytics/TopNPost';
import TopNSubscruiption from './FanzlyAnalytics/TopNSubscruiption';
import TotalEarning from './FanzlyAnalytics/TotalEarning';
import TotalUsers from './FanzlyAnalytics/TotalUsers';
import UserSignup from './FanzlyAnalytics/UserSignup';

class LandingIndex extends React.Component {
  state = {
    cartLogs: ' ',
    ProductRecentView: ' ',
    FavProduct: ' ',
    sidePanel: [
      {
        name: 'Analytics Overview',
        url: `${this.props.match.url}/fanzly-analytics-overview`,
        icon: PollIcon,
        component: Dashboard,
      },

      {
        name: 'Total Earning',
        url: `${this.props.match.url}/total-earning`,
        icon: PollIcon,
        component: TotalEarning,
      },

      {
        name: 'Top Subsrciption',
        url: `${this.props.match.url}/top-subscription`,
        icon: PollIcon,
        component: TopNSubscruiption,
      },

      {
        name: 'Top Post',
        url: `${this.props.match.url}/top-n-post`,
        icon: PollIcon,
        component: TopNPost,
      },

      {
        name: 'Top Creators',
        url: `${this.props.match.url}/top-creators`,
        icon: PollIcon,
        component: TopCreators,
      },

      {
        name: 'User Signup',
        url: `${this.props.match.url}/user-signup`,
        icon: PollIcon,
        component: UserSignup,
      },

      {
        name: 'Total Users',
        url: `${this.props.match.url}/total-users`,
        icon: PollIcon,
        component: TotalUsers,
      },
    ],
  };

  render() {
    if (this.props.location.pathname === '/') {
      this.props.history.push('/dashbord/fanzly-analytics-overview');
    }
    return (
      <div className="dash-board">
        <Header
          title="shoppd - Dashboard"
          data={this.state.sidePanel}
          url={`${this.props.match.url}/fanzly-analytics-overview`}
        ></Header>

        <div
          className="dashbord-body"
          // style={{ height: "100vh", overflow: "auto" }}
        >
          {/* <SidePanel data={this.state.sidePanel}></SidePanel> */}

          <div className="">
            {this.state.sidePanel.map((items, index) => {
              return (
                <Route
                  key={index}
                  path={items.url}
                  name={items.name}
                  exact
                  component={items.component}
                ></Route>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// Create validations for Props, required or type etc.
LandingIndex.propTypes = {
  dispatch: PropTypes.any.isRequired,
  lang: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    index: state.index,
  };
};

export default connect(mapStateToProps, null)(withRouter(LandingIndex));
