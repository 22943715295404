import {getForReport} from '../lib/request';

export const totalEarning = (start_time, end_time, group_by, source, currency, symbol) => {
  let URL = `/analytics/detailed_charts/total-earning?group_by=${group_by}&currency=${currency}&source=${source}&currency_symbol=${symbol}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const topNSubscription = (
  start_time,
  end_time,
  group_by,
  source,
  currency,
  symbol,
  skip,
  limit
) => {
  let URL = `/analytics/detailed_charts/top-n-subscriptions?group_by=${group_by}&currency=${currency}&source=${source}&currency_symbol=${symbol}&skip=${
    skip || 0
  }&limit=${limit || 10}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const topNpost = (start_time, end_time, group_by, source, currency, symbol, skip, limit) => {
  let URL = `/analytics/detailed_charts/top-n-posts?group_by=${group_by}&currency=${currency}&source=${source}&currency_symbol=${symbol}&skip=${
    skip || 0
  }&limit=${limit || 10}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const topCreators = (
  start_time,
  end_time,
  group_by,
  source,
  currency,
  symbol,
  skip,
  limit
) => {
  let URL = `/analytics/detailed_charts/no-of-creators?group_by=${group_by}&currency=${currency}&source=${source}&currency_symbol=${symbol}&skip=${
    skip || 0
  }&limit=${limit || 10}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const userSignup = (
  start_time,
  end_time,
  group_by,
  source,
  currency,
  symbol,
  skip,
  limit
) => {
  let URL = `/analytics/detailed_charts/user-signups?group_by=${group_by}&currency=${currency}&source=${source}&currency_symbol=${symbol}&skip=${
    skip || 0
  }&limit=${limit || 10}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const totalUsers = (
  start_time,
  end_time,
  group_by,
  userType,
  userStatus,
  currency,
  countryId,
  symbol,
  skip,
  limit
) => {
  let URL = `/analytics/detailed_charts/total-users?group_by=${group_by}&user_type=${userType}&status_code=${userStatus}&country=${countryId}&skip=${
    skip || 0
  }&limit=${limit || 10}`;

  if (start_time) {
    URL += `&start_timestamp=${start_time}`;
  }
  if (end_time) {
    URL += `&end_timestamp=${end_time}`;
  }

  return getForReport(URL);
};

export const getCountries = () => {
  let URL = '/countries';

  return getForReport(URL);
};
