import React from 'react';

import {LanguageOptions} from '../../fixtures/language/language';
import {changeLanguage} from '../../redux/actions/language';

const initLanguageChange = (event, props) => {
  props.dispatch(changeLanguage(event.target.value));
};

export const LanguageSelector = (props) => {
  return (
    <select onChange={(event) => initLanguageChange(event, props)}>
      {LanguageOptions &&
        LanguageOptions.map((lang, index) => (
          <option key={'lang-' + index} value={lang.abbr}>
            {lang.name}
          </option>
        ))}
    </select>
  );
};
