import 'antd/dist/antd.css';
//moment import
import moment from 'moment';
import React, {Component} from 'react';

import {dateFilter} from '../../fixtures/dateFilter/dateFIlter';
// component imports
import DateRangePicker from '../dateRangePicker/dateRangePicker';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialGroupBy: true,

      totalGroupBy: [
        {value: 0, label: 'Hour'},
        {value: 1, label: 'Day'},
        {value: 2, label: 'Week'},
        {value: 3, label: 'Month'},
        {value: 4, label: 'Quarter'},
        {value: 6, label: 'Hour Of Day'},
        {value: 7, label: 'Day Of Week'},
      ],
      selectData: [{value: 0, label: 'Hour'}],
      selected: localStorage.getItem('selectedGroupBy')
        ? JSON.parse(localStorage.getItem('selectedGroupBy'))
        : {value: 0, label: 'Hour'},
      date: {
        startDate:
          localStorage.getItem('startDate') ||
          moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: localStorage.getItem('endDate') || moment().format('YYYY-MM-DD HH:mm:ss'),
      },
    };
  }

  componentDidMount() {
    if (this.props && this.props.getGroupBy === false) {
      this.props.handleDateChange(this.state.date.startDate, this.state.date.endDate);
    }
  }

  handleDateRange = (...arges) => {
    let startDate = arges[0].length !== 0 ? arges[0][0].format('YYYY-MM-DD HH:mm:ss') : null;
    let endDate = arges[0].length !== 0 ? arges[0][1].format('YYYY-MM-DD HH:mm:ss') : null;

    startDate = startDate && moment(startDate).startOf('day');
    if (endDate) {
      endDate =
        moment().diff(endDate, 'H') === 0 || moment().diff(endDate, 'H') === '0'
          ? moment()
          : moment(endDate).endOf('day');
    }

    let numberOfDays = endDate?.diff(startDate, 'days') ?? 0;

    if (endDate) {
      const isToday = endDate.isSame(moment(), 'day');

      endDate = isToday
        ? moment().format('YYYY-MM-DD HH:mm:ss')
        : moment(endDate || new Date())
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss');
    }

    this.setState({
      date: {
        startDate,
        endDate,
      },
    });

    if (this.props && this.props.getGroupBy) {
      return this.changeGroupByPeriodList(numberOfDays);
    }
    return this.props.handleDateChange(this.state.date.startDate, this.state.date.endDate);
  };

  changeGroupByPeriodList = (numberOfDays, condition) => {
    let listOfGroupBy = [];

    if (condition) {
      let startDate = moment(this.state.date.startDate).startOf('day');
      let endDate =
        moment().diff(this.state.date.startDate, 'H') === 0 ||
        moment().diff(this.state.date.endDate, 'H') === '0'
          ? moment()
          : moment(this.state.date.endDate).endOf('day');

      numberOfDays = endDate.diff(startDate, 'days');
    }
    if (numberOfDays === 0) {
      listOfGroupBy.push(this.state.totalGroupBy[0]);
      this.setState({selectData: [...listOfGroupBy]}, () =>
        this.checkGroupByExistsOrNot(listOfGroupBy)
      );
    } else if (numberOfDays > 0 && numberOfDays <= 7) {
      listOfGroupBy.push(this.state.totalGroupBy[0]);
      listOfGroupBy.push(this.state.totalGroupBy[1]);
      listOfGroupBy.push(this.state.totalGroupBy[5]);
      this.setState({selectData: listOfGroupBy}, () => this.checkGroupByExistsOrNot(listOfGroupBy));
    } else if (numberOfDays > 7 && numberOfDays <= 30) {
      listOfGroupBy.push(this.state.totalGroupBy[1]);
      listOfGroupBy.push(this.state.totalGroupBy[2]);
      listOfGroupBy.push(this.state.totalGroupBy[5]);
      listOfGroupBy.push(this.state.totalGroupBy[6]);
      this.setState({selectData: listOfGroupBy}, () => this.checkGroupByExistsOrNot(listOfGroupBy));
    } else if (numberOfDays > 30 && numberOfDays <= 90) {
      listOfGroupBy.push(this.state.totalGroupBy[1]);
      listOfGroupBy.push(this.state.totalGroupBy[2]);
      listOfGroupBy.push(this.state.totalGroupBy[3]);
      listOfGroupBy.push(this.state.totalGroupBy[5]);
      listOfGroupBy.push(this.state.totalGroupBy[6]);
      this.setState({selectData: listOfGroupBy}, () => this.checkGroupByExistsOrNot(listOfGroupBy));
    } else {
      listOfGroupBy.push(this.state.totalGroupBy[2]);
      listOfGroupBy.push(this.state.totalGroupBy[3]);
      listOfGroupBy.push(this.state.totalGroupBy[5]);
      listOfGroupBy.push(this.state.totalGroupBy[6]);
      this.setState({selectData: listOfGroupBy}, () => this.checkGroupByExistsOrNot(listOfGroupBy));
    }

    if (!listOfGroupBy.includes(this.state.selected)) {
      this.setState({selected: listOfGroupBy[0]}, () =>
        this.checkGroupByExistsOrNot(listOfGroupBy)
      );
    }
  };

  checkGroupByExistsOrNot = (listOfGroupBy) => {
    const {date, selectData, selected} = this.state;

    if (!listOfGroupBy.includes(this.state.selected)) {
      this.setState({selected: listOfGroupBy[0]});
    }
    if (this.props && this.props.getGroupBy) {
      let data = localStorage.getItem('selectedGroupBy')
        ? JSON.parse(localStorage.getItem('selectedGroupBy'))
        : {value: 0, label: 'Hour'};

      let return_data = this.state.initialGroupBy ? data : selected;

      this.setState({initialGroupBy: false});

      return this.props.handleDateChange(
        date.startDate,
        date.endDate,
        selectData,
        return_data
        // seleted
      );
    } else {
      return this.props.handleDateChange(date.startDate, date.endDate);
    }
  };

  render() {
    return (
      <div
        className="d-flex d-flex align-items-center mr-3"
        style={{justifyContent: this.props.text ? 'center' : 'flex-start'}}
      >
        <div
          className="mr-2 f-16 globalFontSize"
          style={{display: this.props.text ? 'block' : 'none'}}
        >
          Date {this.props.heading ? 'Range' : 'Picker'} :
        </div>
        <DateRangePicker
          id="datePicker"
          ranges={{...dateFilter}}
          value={[this.state.date.startDate, this.state.date.endDate]}
          onChange={this.handleDateRange}
        />
      </div>
    );
  }
}

export default DatePicker;
