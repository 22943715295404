// eslint-disable-next-line no-unused-vars
import React from 'react';
import ReactDOM from 'react-dom';
import {addLocaleData} from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
// import { hydrate, render } from 'react-dom'
// eslint-disable-next-line no-unused-vars
import {Provider} from 'react-redux';

import * as serviceWorker from './serviceWorker';
import App from './App';
import ConfigureStore from './redux/store';
import './static/css/index.css';

// define locale dataset to our scope
addLocaleData(en);
addLocaleData(pt);
addLocaleData(es);

const rootElement = document.getElementById('root');

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider store={ConfigureStore()}>
    <App />
  </Provider>,
  rootElement
);

serviceWorker.register();
