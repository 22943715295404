import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from 'react';

import PaginationJs from '../../../../../components/paginationJs/paginationJs';
//import css
import './index.css';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: '150px',
    fontSize: '10px',
    fontFamily: 'Poppins',
    maxWidth: '250px',
    minWidth: '180px',
    textAlign: 'center',
    fontWeight: 600,
    color: '#484848',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  data: {
    width: '180px',
    fontSize: '10px',
    fontFamily: 'Poppins',
    maxWidth: '250px',
    minWidth: '180px',
    textAlign: 'center',
  },
  menuItem: {
    width: '300px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  selectCountryButton: {
    background: '#FFF',
    border: '1px solid #bbb',
    borderRadius: '4px',
    padding: '7px 12px',
    marginLeft: '10px',
  },
});

class index extends Component {
  constructor() {
    super();
    this.state = {
      filterWindow: false,
    };
  }

  render() {
    const {classes} = this.props;

    return (
      <div className="table">
        <div className="tableWrapper">
          <Paper className={classes.root + ' react-table'}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.props.showTable &&
                    this.props.columns.map((k, i) => (
                      <Tooltip title={k.Header} placement="top" key={i}>
                        <TableCell className={classes.dataHeader} key={i}>
                          {k.Header}
                        </TableCell>
                      </Tooltip>
                    ))}
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.dataHeader}>Summary</TableCell>
                  {Object.values({1: '', 2: '', 3: '', 4: '', ...this.props.summary})
                    // .splice(1)
                    .map((k, i) => (
                      <TableCell className={classes.dataHeader} key={i}>
                        {this.props.returnDataWithTwoDecsOrInteger(k, i + 1)}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.logsData.map((row, i) => {
                  return (
                    <TableRow
                      key={i}
                      style={i % 2 === 0 ? {background: '#F6F6F6'} : {background: '#FFF'}}
                    >
                      {/* <TableCell
                        className={classes.data}
                        component="th"
                        scope="row"
                      >
                        {this.props.dateRender(row.dateTime)}
                      </TableCell> */}
                      {Object.values(row)
                        // .splice(1)
                        .map((k, i) => (
                          <TableCell className={classes.data} component="th" scope="row" key={i}>
                            {this.props.returnDataWithTwoDecsOrInteger(k, i)}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </div>

        {/* paginationWrapper */}
        <div className="paginationWrapper">
          <PaginationJs
            changeActivePage={this.props.changeActivePage}
            activePage={this.props.page}
            totalData={this.props.dataCount}
            dataToShowHandler={this.props.dataToShowHandler}
            dataToShow={this.props.dataToShow}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(index);
