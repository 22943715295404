//library import
//helper
import async from 'async';
import moment from 'moment';
import React, {Component} from 'react';
import Masonry from 'react-masonry-css';
import {connect} from 'react-redux';

import Snackbar from '../../../components/snackbar/snackbar';
import {statusFilter} from '../../../fixtures/dateFilter/statusFilter';
import {countries} from '../../../fixtures/dummyApi/countries';
import {
  dateTimeFormatForGraph,
  isFloat,
  setInLocalStorage,
  tooltipFormatter,
} from '../../../lib/helper';
import {setIndex} from '../../../redux/actions/auth';
import {
  dashboardTopCreators,
  dashboardTopNPosts,
  dashboardTopNSubscription,
  dashboardTotalEarning,
} from '../../../services/fanzlyOverview';
import '../../../static/scss/globalComponent.scss';
import '../Common.scss';
// css file
import '../Overview.scss';
import '../global-font-css.css';
import {chartConfig} from './graphConfig/chartConfig';
import TotalEarning from './graphs/totalEarningOverview';
import Headers from './header/index.js';
import Table from './table/topNFrame';

/**
 * @Author Jai
 * @Date 14 July, 2021
 * @Description Created a dashboard for Fanzly Analytics Overview and added a Total Earning graph as well added Date Range and Currency filter
 */

class Dashboard extends Component {
  state = {
    dataLoaded: false,
    isOpenSnackbar: false,
    snackbarMessage: '',

    storeIdValue: '0',
    storeData: statusFilter,

    isDisplayChart: false,
    noDataText: '',

    // currency
    selectedCurrency: 'USD',
    previousCurrency: 'USD',
    dataForCurrency: 'USD',

    // selectedCurrency: window._env_.CURRENCY,
    // previousCurrency: window._env_.CURRENCY,
    // dataForCurrency: window._env_.CURRENCY,

    filterData: [...countries],
    anchorEl: null,
    currencyFilter: {
      label: 'Currency filter',
      input: {
        name: 'currencyFilter',
        type: 'text',
        value: '',
      },
    },

    totalEarningCount: 0,
    totalEarningLoader: true,
    totalEarningConfig: chartConfig,

    topNPostCount: '',
    topNLoader: true,
    topNPostTable: [],

    subscriptionCount: '',
    topNSubscriptionLoader: true,
    topNSubscriptionTable: [],

    topCreatorsLoader: true,
    topCreatorsTable: [],

    startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment().format('YYYY-MM-DD HH:mm:ss'),

    groupByName: {value: 0, label: 'Hour'},
    totalSource: 'ALL',
    topSource: 'REVENUE',
    groupBy: 0,
  };

  componentDidMount = () => {
    let width = window.innerWidth;

    this.setState({width});
    window.addEventListener('resize', this.heightHandler);
    const {startDate, endDate, groupByName} = this.state;

    localStorage.setItem('startDate', startDate);
    localStorage.setItem('endDate', endDate);
    localStorage.setItem('selectedGroupBy', JSON.stringify(groupByName));

    this.getData(
      moment(this.state.startDate).unix(),
      moment(this.state.endDate).unix(),
      this.state.groupBy,
      this.state.selectedCurrency,
      this.state.totalSource,
      this.state.topSource
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedCurrency !== this.state.selectedCurrency ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      this.state.groupBy !== prevState.groupBy
    ) {
      this.setState({
        topNLoader: true,
        tableLoader: true,
        totalEarningLoader: true,
        topNSubscriptionLoader: true,
        topCreatorsLoader: true,
        isDisplayChart: false,
        noDataText: 'Loading Data ...',
      });

      this.getData(
        moment(this.state.startDate).unix(),
        moment(this.state.endDate).unix(),
        this.state.groupBy,
        this.state.selectedCurrency,
        this.state.totalSource,
        this.state.topSource
      );
    }
  }

  valueFormatter = (value) => {
    if (value >= 10000 && value < 1000000) {
      let kValue = value / 1000;
      let isDecimal = kValue % 1;

      if (isDecimal) {
        return kValue.toFixed(0) + ' K';
      }
      return kValue.toFixed(0) + ' K';
    }
    if (value >= 100000) {
      let kValue = value / 1000000;

      return kValue.toFixed(2) + ' M';
    }
    return Number(value.toFixed(2));
  };

  getGraphConfigData = (response, config) => {
    try {
      let xCategories = dateTimeFormatForGraph(
        this.state.groupByName.label || 'Hour',
        response.graph.xaxis.categories
      );

      let format = {
        Hour: 'll H:00',
        Day: 'll',
        Week: 'll',
        Month: 'MMM YY',
        'Hour Of Day': 'HH:00',
      };

      let label = this.state.groupByName.label;
      let tickAmount = xCategories.length > 7 ? 5 : xCategories.length;
      let xaxisType =
        xCategories.length < 8 ||
        label === 'Hour' ||
        label !== 'Day' ||
        label === 'Year' ||
        label === 'Quarter' ||
        label === 'Day Of Week'
          ? 'category'
          : 'datetime';
      let tickPlacement = xaxisType !== 'datetime' ? 'between' : 'on';

      let formatter =
        config === 'sales'
          ? (value) => {
              if (value >= 10000 && value < 1000000) {
                let kValue = value / 1000;

                return Number(kValue.toFixed(2)) + ' K';
              }
              if (value >= 1000000 && value < 1000000000) {
                let kValue = value / 1000000;

                return Number(kValue.toFixed(2)) + ' M';
              }

              if (value >= 1000000000 && value < 1000000000000) {
                let kValue = value / 1000000000;

                return Number(kValue.toFixed(2)) + ' B';
              }

              if (value >= 1000000000000) {
                let kValue = value / 1000000000000;

                return Number(kValue.toFixed(2)) + ' T';
              }
              return value && Number.isInteger(value)
                ? Number(value.toFixed())
                : isFloat(value)
                ? value.toFixed(2)
                : value;
            }
          : (val) => (parseInt(val) === val ? val : '');

      response.graph.xaxis.categories = [...xCategories];
      //graph configuration
      let graphData = {
        series: [...response.graph.series],
        options: {
          plotOptions: {bar: {columnWidth: '30%'}},
          title: {
            text: '',
            align: 'left',
            style: {fontSize: '18px', fontWeight: 500},
          },
          dataLabels: {enabled: false},
          background: {
            enabled: false,
          },
          legend: {
            position: 'top',
            // showForSingleSeries: true,
            horizontalAlign: 'center',
            onItemClick: {
              toggleDataSeries: true,
            },
            onItemHover: {
              highlightDataSeries: true,
            },
          },

          chart: {
            width: '100%',
            toolbar: {
              show: false,
            },
            zoom: {
              type: 'x',
              enabled: false,
              autoScaleYaxis: true,
            },
          },
          xaxis: {
            title: {
              text: response.graph.xaxis.title,

              style: {
                fontSize: '11px',
                fontWeight: 700,
              },
            },
            // categories: [...response.graph.xaxis.categories],
            categories: xCategories,
            type: xaxisType,
            tickAmount: tickAmount,
            tickPlacement: tickPlacement,
            tooltip: {
              enabled: false,
            },
            labels: {
              show: true,
              rotate: -45,
              hideOverlappingLabels: true,
              rotateAlways: true,
              trim: true,
              style: {fontSize: '11px'},
              formatter: function (value) {
                let isValid = moment(value).isValid() && label !== 'Year';

                return isValid ? moment(value).format(format[label]) : value;
              },
            },
          },
          grid: {
            // providing padding in the graph
            padding: {
              left: 30,
            },
          },

          yaxis: {
            title: {
              text: response.graph.yaxis.title,
              style: {
                fontSize: '11px',
                fontWeight: 700,
              },
            },
            floating: false,
            labels: {
              show: true,
              offsetX: 15,
              formatter: formatter,
            },
          },

          tooltip: {
            enabled: true,
            followCursor: true,
            shared: true,
            y: {
              formatter:
                config === 'sales'
                  ? (val) => {
                      return tooltipFormatter(val, this.state.dataForCurrency, true);
                    }
                  : undefined,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
        },
      };

      return graphData;
    } catch (error) {
      console.log('Error in Chart Configuration ', error);
      return error;
    }
  };

  getData = (start_date, end_date, groupBy, currency, totalSource, topSource) => {
    setInLocalStorage('currency', this.state.selectedCurrency);
    async.parallel(
      {
        totalEarning: (callback) => {
          dashboardTotalEarning(start_date, end_date, groupBy || 0, currency, totalSource)
            .then((response) => {
              try {
                let totalEarningConfig = this.getGraphConfigData(response.data, 'sales');

                this.setState({
                  totalEarningConfig,
                  totalEarningCount: response.data.total,
                  totalEarningLoader: false,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  totalEarningLoader: false,
                  totalEarningCount: 0,
                  totalEarningConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        title: 'No data for selected date range',
                      },
                      xaxis: {labels: {show: false}},
                    },
                  },
                });
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === 'currency conversion not found'
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  totalEarningLoader: false,
                  snackbarMessage: 'selected currency is not supported',
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: '',
                  });
                }, 1000);
              } else {
                this.setState({
                  // currencyData: this.state.previousCurrencyData,
                  totalEarningLoader: false,
                  isOpenSnackbar: true,
                  totalEarningCount: 0,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? 'Internal Server Error'
                      : 'No data found for Total Earning!',
                  totalEarningConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        text: 'No data for selected date range',
                        style: {
                          fontSize: '12px',
                          fontWeight: 400,
                          fontFamily: 'sans-serif',
                          color: '#263238',
                        },
                      },
                      legend: {
                        showForSingleSeries: false,
                      },
                      xaxis: {labels: {show: false}},
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: '',
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        topNPosts: (callback) => {
          dashboardTopNPosts(start_date, end_date, groupBy || 0, currency, totalSource)
            .then((response) => {
              // console.log('res', response.data.data)
              const talbeRes = response.data.data.map((data) => {
                delete data.id;
                return data;
              });

              try {
                if (response && response.data.data) {
                  this.setState({
                    topNLoader: false,
                    topNPostCount: response.data.data.length,
                    topNPostTable: talbeRes,
                    dataForCurrency: this.state.selectedCurrency,
                  });
                }
              } catch (error) {
                this.setState({
                  topNLoader: false,
                  topNPostTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topNLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === 'currency conversion not found'
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  snackbarMessage: 'selected currency is not supported',
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: '',
                  });
                }, 1000);
              } else {
                this.setState({
                  topNLoader: false,
                  topNPostTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topNLoader: false,
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        topNSubscription: (callback) => {
          dashboardTopNSubscription(start_date, end_date, groupBy || 0, currency, totalSource)
            .then((response) => {
              try {
                if (response && response.data.data) {
                  this.setState({
                    subscriptionCount: response.data.data.length,
                    topNSubscriptionLoader: false,
                    topNSubscriptionTable: response.data.data,
                    dataForCurrency: this.state.selectedCurrency,
                  });
                }
              } catch (error) {
                this.setState({
                  topNSubscriptionLoader: false,
                  topNSubscriptionTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topNSubscriptionLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === 'currency conversion not found'
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  snackbarMessage: 'selected currency is not supported',
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: '',
                  });
                }, 1000);
              } else {
                this.setState({
                  topNSubscriptionLoader: false,
                  topNSubscriptionTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topNSubscriptionLoader: false,
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        topCreators: (callback) => {
          dashboardTopCreators(start_date, end_date, groupBy || 0, currency, topSource)
            .then((response) => {
              try {
                if (response && response.data.data) {
                  this.setState({
                    // subscriptionCount: response.data.data.length,
                    topCreatorsLoader: false,
                    topCreatorsTable: response.data.data,
                    dataForCurrency: this.state.selectedCurrency,
                  });
                }
              } catch (error) {
                this.setState({
                  topCreatorsLoader: false,
                  topCreatorsTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topCreatorsLoader: false,
                  });
                }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === 'currency conversion not found'
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  snackbarMessage: 'selected currency is not supported',
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: '',
                  });
                }, 1000);
              } else {
                this.setState({
                  topCreatorsLoader: false,
                  topCreatorsTable: [],
                });
                setTimeout(() => {
                  this.setState({
                    topCreatorsLoader: false,
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
      },
      (err) => {
        if (err) {
          console.log(err);
        }
      }
    );
  };

  sourceByTotalEaring = (start_date, end_date, groupBy, currency, totalSource) => {
    dashboardTotalEarning(start_date, end_date, groupBy || 0, currency, totalSource)
      .then((response) => {
        try {
          let totalEarningConfig = this.getGraphConfigData(response.data, 'sales');

          this.setState({
            totalEarningConfig,
            totalEarningCount: response.data.total,
            totalEarningLoader: false,
            dataForCurrency: this.state.selectedCurrency,
          });
        } catch (error) {
          this.setState({
            totalEarningLoader: false,
            totalEarningCount: 0,
            totalEarningConfig: {
              ...chartConfig,
              options: {
                ...chartConfig.options,
                noData: {
                  ...chartConfig.options.noData,
                  title: 'No data for selected date range',
                },
                xaxis: {labels: {show: false}},
              },
            },
          });
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message === 'currency conversion not found'
        ) {
          this.setState({
            isOpenSnackbar: true,
            totalEarningLoader: false,
            snackbarMessage: 'selected currency is not supported',
          });
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
              snackbarMessage: '',
            });
          }, 1000);
        } else {
          this.setState({
            // currencyData: this.state.previousCurrencyData,
            totalEarningLoader: false,
            isOpenSnackbar: true,
            totalEarningCount: 0,
            snackbarMessage:
              error.response && error.response.status === 500
                ? 'Internal Server Error'
                : 'No data found for Total Earning!',
            totalEarningConfig: {
              ...chartConfig,
              options: {
                ...chartConfig.options,
                noData: {
                  ...chartConfig.options.noData,
                  text: 'No data for selected date range',
                  style: {
                    fontSize: '12px',
                    fontWeight: 400,
                    fontFamily: 'sans-serif',
                    color: '#263238',
                  },
                },
                legend: {
                  showForSingleSeries: false,
                },
                xaxis: {labels: {show: false}},
              },
            },
          });
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
              snackbarMessage: '',
            });
          }, 1000);
        }
      });
  };

  sourceBytopCreators = (start_date, end_date, groupBy, currency, topSource) => {
    dashboardTopCreators(start_date, end_date, groupBy || 0, currency, topSource)
      .then((response) => {
        try {
          if (response && response.data.data) {
            this.setState({
              topCreatorsLoader: false,
              topCreatorsTable: response.data.data,
              dataForCurrency: this.state.selectedCurrency,
            });
          }
        } catch (error) {
          this.setState({
            topCreatorsLoader: false,
            topCreatorsTable: [],
          });
          setTimeout(() => {
            this.setState({
              topCreatorsLoader: false,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message === 'currency conversion not found'
        ) {
          this.setState({
            isOpenSnackbar: true,
            snackbarMessage: 'selected currency is not supported',
          });
          setTimeout(() => {
            this.setState({
              isOpenSnackbar: false,
              snackbarMessage: '',
            });
          }, 1000);
        } else {
          this.setState({
            topCreatorsLoader: false,
            topCreatorsTable: [],
          });
          setTimeout(() => {
            this.setState({
              topCreatorsLoader: false,
            });
          }, 1000);
        }
      });
  };

  headerStateHandler = (stateName, stateValue) => {
    if (stateName === 'selectedCurrency' && stateValue !== this.state.selectedCurrency) {
      this.setState({
        previousCurrency: this.state.selectedCurrency,
        selectedCurrency: stateValue,
      });
    }
    if (stateName === 'date' && stateValue) {
      this.setState({
        startDate: stateValue.startDate,
        endDate: stateValue.endDate,
      });
    }
    if (stateName === 'groupBy' && (stateValue === 0 || stateValue)) {
      this.setState({
        groupBy: stateValue,
      });
    }
    if (stateName === 'groupByName' && stateValue) {
      this.setState({
        groupByName: stateValue,
      });
    }
  };

  totalEarningDropDown = (e) => {
    this.setState(
      {totalSource: e, totalEarningLoader: true},
      this.sourceByTotalEaring(
        moment(this.state.startDate).unix(),
        moment(this.state.endDate).unix(),
        this.state.groupBy,
        this.state.selectedCurrency,
        e
      )
    );
  };

  topCreatorsDropDown = (e) => {
    this.setState(
      {topource: e, topCreatorsLoader: true},
      this.sourceBytopCreators(
        moment(this.state.startDate).unix(),
        moment(this.state.endDate).unix(),
        this.state.groupBy,
        this.state.selectedCurrency,
        e
      )
    );
  };

  render() {
    const breakpointColumnsObj = {
      default: 3,
      1100: 2,
      700: 1,
      500: 1,
    };

    return (
      <div className="overviewWrapper">
        <div className="head">
          <div className="title">
            <span>Analytics Overview</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mt-2">
              <Headers
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                groupBy={this.state.groupBy}
                groupByName={this.state.groupByName}
                currency={this.state.selectedCurrency}
                headerStateHandler={this.headerStateHandler}
              />
            </div>
          </div>
        </div>

        <div className="overviewBody" style={{marginTop: '7rem'}}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <TotalEarning
              earningCount={
                this.state.totalEarningCount ? this.valueFormatter(this.state.totalEarningCount) : 0
              }
              loader={this.state.totalEarningLoader}
              config={this.state.totalEarningConfig}
              currency={this.state.selectedCurrency}
              sourceChangeHandler={(source) => this.totalEarningDropDown(source)}
            />

            {/* Top N Post */}
            <Table
              viewActivity="top-n-post"
              title={`Top ${this.state.topNPostCount} Post`}
              table={this.state.topNPostTable}
              loader={this.state.topNLoader}
              currency={this.state.selectedCurrency}
              // label='TopNPost'
            />

            {/* Top N Subscription */}
            <Table
              viewActivity="top-subscription"
              title={`Top ${this.state.subscriptionCount} Subscription`}
              table={this.state.topNSubscriptionTable}
              loader={this.state.topNSubscriptionLoader}
              currency={this.state.selectedCurrency}
            />

            {/* Top Creators */}
            <Table
              viewActivity="top-creators"
              title="Top Creators"
              table={this.state.topCreatorsTable}
              loader={this.state.topCreatorsLoader}
              currency={this.state.selectedCurrency}
              sourceChangeHandler={(source) => this.topCreatorsDropDown(source)}
            />
          </Masonry>
        </div>
        <Snackbar open={this.state.isOpenSnackbar} message={this.state.snackbarMessage} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIndex: (index) => dispatch(setIndex(index)),
  };
};

export default connect(null, mapDispatchToProps)(Dashboard);
