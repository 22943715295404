import LandingIndex from './pages/index/index';
import SearchPage from './pages/search/search';

const BaseRoutes = [
  {
    path: '/dashbord',
    name: 'dashbord',
    component: LandingIndex,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
  },
];

export {BaseRoutes};
