// eslint-disable-next-line no-unused-vars
import * as actionTypes from './actions/actionTypes';

export const initialState = {
  testKey: false,
  lang: 'en',
  isLogin: false,
  index: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // create your cases and update state here
    case actionTypes.LANGUAGE_CHANGE:
      // return {
      //   ...state
      // }
      return {
        ...state,
        lang: action.lang,
      };
    case actionTypes.ISLOGIN:
      return {
        ...state,
        isLogin: action.payload.isLogin,
      };
    case actionTypes.GET_INDEX: {
      return {
        ...state,
        index: action.data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
