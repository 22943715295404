import moment from 'moment';

export const dateFilter = {
  Today: [moment().startOf('day'), moment()],
  Yesterday: [
    moment().subtract(1, 'days').startOf('day'),
    moment().subtract(1, 'days').endOf('day'),
  ],
  'Last 7 days': [moment().subtract(6, 'days').startOf('day'), moment()],
  'Last month': [
    moment().subtract(1, 'months').startOf('month'),
    moment().subtract(1, 'months').endOf('month'),
  ],
  'Last year': [
    moment().subtract(1, 'years').startOf('year'),
    moment().subtract(1, 'years').endOf('year'),
  ],
  'This year': [moment().startOf('year'), moment()],
};
