import {Menu, MenuItem, withStyles} from '@material-ui/core';
import moment from 'moment';
import React, {Component} from 'react';
import {CSVLink} from 'react-csv';
import {withRouter} from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import Input from '../../../../components/InputBox/Input';
import Pbutton from '../../../../components/button/Button';
import Filters from '../../../../components/filter/location/filter';
import Modal from '../../../../components/modal/modal';
import ReactSelect from '../../../../components/reactSelect/reactSelect';
import Snackbar from '../../../../components/snackbar/snackbar';
import {countries} from '../../../../fixtures/dummyApi/countries';
import {getCurrencySymbol, tooltipFormatter} from '../../../../lib/helper';
import {topNpost} from '../../../../services/fanzlyDetailsApi';
import '../../../../static/scss/globalComponent.scss';
// Css
import '../index.css';
import TableComponent from './table/index';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: '150px',
    fontSize: '10px',
    fontFamily: 'Poppins',
    maxWidth: '170px',
    minWidth: '150px',
    textAlign: 'center',
    fontWeight: 600,
    color: '#484848',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  data: {
    width: '150px',
    fontSize: '10px',
    fontFamily: 'Poppins',
    maxWidth: '170px',
    minWidth: '150px',
    textAlign: 'center',
  },
  menuItem: {
    width: '300px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  selectCountryButton: {
    background: '#FFF',
    border: '1px solid #bbb',
    borderRadius: '4px',
    padding: '7px 12px',
    marginLeft: '10px',
  },
});

class TopNPost extends Component {
  myRef = React.createRef();
  state = {
    snackBarMessage: '',
    showSnackbar: false,
    isExport: false,
    vehicle_type_id: 0,
    isMoreThanThousand: false,
    noDataText: 'Loading Data ...',

    loader: true, //loader state
    noData: false,
    dataLoaded: false,

    source: [
      {label: 'All', value: 'ALL'},
      {label: 'Post Purchase', value: 'POST_PURCHASE'},
      {label: 'Subscription', value: 'SUBSCRIPTION'},
      {label: 'Tip', value: 'TIP'},
      {label: 'Paid Chat', value: 'PAID_CHAT'},
    ],
    selectedsource: {label: 'All', value: 'ALL'},

    // curreency state
    anchorEl: null,
    currencyData: localStorage.getItem('currency')
      ? JSON.parse(localStorage.getItem('currency'))
      : 'USD',
    previousCurrencyData: localStorage.getItem('currency')
      ? JSON.parse(localStorage.getItem('currency'))
      : 'USD',
    filterData: [...countries],
    currencyFilter: {
      label: 'Currency filter',
      input: {
        name: 'currencyFilter',
        type: 'text',
        value: '',
      },
    },

    /**
     * @DescriptionDocer uncomment below state and comment above all state realted to currency
     */
    // currencyData: window._env_.CURRENCY,
    // previousCurrencyData: window._env_.CURRENCY,

    // start and end date
    date: {
      startDate:
        localStorage.getItem('startDate') != null
          ? localStorage.getItem('startDate')
          : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      endDate:
        localStorage.getItem('endDate') != null
          ? localStorage.getItem('endDate')
          : moment().format('YYYY-MM-DD HH:mm:ss'),
    },

    // set group by value and list
    selected: localStorage.getItem('selectedGroupBy')
      ? JSON.parse(localStorage.getItem('selectedGroupBy'))
      : {value: 0, label: 'Hour'},
    selectData: [{value: 0, label: 'Hour'}],

    logsData: [], //table data
    columns: [], //table columns
    dataCount: 0,
    skip: 0, //starting point
    limit: 10, //ending point
    page: 1, //pagination page
    dataToShow: 10, //50 or 100 data to show
    showTable: false,
  };

  /**
   * @Author Jai
   * @Date 15 July, 2021
   * @Description Created a Top N Post Chart and added a DateRange, GroupBy, Currency and Source filter
   */

  componentDidMount() {
    this.intermediate();
  }

  // this function call whenever there is a update in the whole component
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.date.startDate !== prevState.date.startDate ||
      this.state.date.endDate !== prevState.date.endDate ||
      this.state.selectedsource.value !== prevState.selectedsource.value ||
      this.state.currencyData !== prevState.currencyData ||
      this.state.selected.value !== prevState.selected.value
    ) {
      this.setState({
        loader: true,
        noDataText: 'Loading Data ...',
      });
      // setInLocalStorage("currency", this.state.currencyData);
      this.intermediate();
    }
  }

  intermediate = () => {
    let symbol = getCurrencySymbol(this.state.currencyData);

    this.getData(
      this.state.date.startDate,
      this.state.date.endDate,
      parseInt(this.state.selected.value),
      this.state.selectedsource.value,
      this.state.currencyData,
      symbol,
      this.state.skip,
      this.state.limit
    );
  };

  getData = (start_timestamp, end_timestamp, groupBy, source, currency, symbol, skip, limit) => {
    topNpost(
      moment(start_timestamp).unix(),
      moment(end_timestamp).unix(),
      groupBy || 0,
      source,
      currency,
      symbol,
      skip,
      limit
    )
      .then((response) => {
        // console.log('response', response.data)
        // let xcat = []
        // let graphSeries = []
        // response.data.graph.map((data)=>{
        //   xcat.push(data['dateTime'])
        //   graphSeries.push(Number(data['amount']).toFixed())
        //   return xcat
        // })

        // let xCategories = dateTimeFormatForGraph(
        //   this.state.selected.label || "Hour",
        //   xcat
        // );

        // //graph configuration
        // let graphData = {
        //   series: [{'name':"Payment Amount", data: graphSeries}],
        //   options: {
        //     title: {
        //       text: "",
        //       align: "left",
        //       style: { fontSize: "18px", fontWeight: 500 },
        //     },
        //     dataLabels: { enabled: false },
        //     chart: {
        //       height: 350,
        //       type: 'bar'
        //     },
        //     xaxis: {
        //       type: "category",
        //       categories: xCategories,
        //       title: {
        //         text: response.data.yaxis_title,
        //         style: {
        //           fontSize: "11px",
        //           fontWeight: 700,
        //           paddingRight: "20px",
        //         },
        //       },
        //       tickPlacement: "on",
        //       tooltip: {
        //         enabled: false,
        //       },
        //     },

        //     legend: {
        //       position: "top",
        //       showForSingleSeries: true,
        //     },
        //     yaxis: {
        //       opposite: false,
        //       labels: {
        //         show: true,
        //         style: { fontSize: "11px" },
        //         formatter: (val, index) => {
        //           return tooltipFormatter(val, this.state.currencyData, true);
        //         },
        //       },
        //     },
        //     tooltip: {
        //       enabled: true,
        //       followCursor: true,
        //       y: {
        //         formatter: (val, series) => {
        //           return tooltipFormatter(val, this.state.currencyData, true);
        //         },

        //         title: {
        //           formatter: (seriesName) => seriesName,
        //         },
        //       },
        //     },
        //   },
        // };

        // table data
        let columns = Object.keys(response.data.data[0]); // column name from api
        let cold = columns.map((item, index) => {
          // modified column name according to table structure
          return {
            Header: item[0].toUpperCase() + item.slice(1),
            accessor: (data) => {
              console.log('true----------');
              if (item.toLowerCase().includes('post created on') && this.state.selected.value < 1) {
                return moment(Object.values(data)[index]).format('DD-MMMM-YYYY LT');
              } else {
                let type = typeof Object.values(data)[index];

                if (type === 'number') {
                  return Object.values(data)[index] && Object.values(data)[index].toFixed(2);
                } else {
                  return Object.values(data)[index];
                }
              }
            },
            id: item,
            show: true,
            width: 150,
          };
        });

        let summaryData = response.data.summary;

        delete summaryData.Revenue;

        this.setState({
          showTable: true,
          logsData: response.data.data, // table data
          columns: cold, // table column name
          loader: false,
          dataCount: response.data.count,
          dataLoaded: true,
          summary: summaryData,

          // graphData, // chart data
          noDataText: '',
          previousCurrencyData: this.state.currencyData,
        });
      })
      .catch((error) => {
        if (!this.state.date.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: 'Please select a date range',
          });
        } else {
          let message =
            error && error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Data Not Found For the Given Date Range!';

          this.setState({
            dataLoaded: false,
            showTable: false,
            loader: false,
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: 'No data for the selected date range',
            currencyData: this.state.previousCurrencyData,
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
          });
        }, 1000);
        console.log('Api error', error);
      });
  };
  // ========= End of getData function ============ //

  sourceSelectHandler = (option) => {
    this.setState({
      selectedsource: option,
    });
  };

  // Date manipulating
  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState({
      date: {
        startDate: startDate,
        endDate: endDate,
      },
      selectData: groupBy,
      selected: selectedGroupBy,
      loader: true,
    });
  };

  //  groupBy manipulating
  selectHandler = (option) => {
    this.setState({selected: option, loader: true});
  };

  // Change currency
  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });

    let filterData = countries.filter((item) => {
      return (
        item.currencies.findIndex(
          (item) => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase())
        ) !== -1 || item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });

    this.setState({
      filterData,
    });
  };

  // open the currency search box
  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  // close the currency search box
  handleClose = () => {
    this.setState({anchorEl: null});
  };

  getCurrencyData = (obj) => {
    // console.log('currencies', obj.currencies[0].code, obj)
    // currency_symbol
    // let symbol =  getCurrencySymbol(obj.currencies[0].code, obj)
    // console.log('symbol', symbol)
    this.setState({
      currencyData: obj.currencies[0].code,
      // currency_symbol:symbol
    });
    this.handleClose();
  };

  returnDataWithTwoDecsOrInteger = (n, i) => {
    let tableIndex = [6];
    let format = {
      Hour: 'll H:00',
      'Day Of Week': 'ddd',
      Day: 'll',
      Week: 'll',
      'Hour Of Day': 'HH:00',
      Year: 'll',
      Month: 'MMM YY',
    };

    if (i === 4 && n !== '') {
      return moment(n).format(format[this.state.selected.label]);
    }

    if (Number(n) === n && n % 1 === 0) {
      return tableIndex.includes(i) ? tooltipFormatter(n, this.state.currencyData, true) : n;
    } else if (Number(n) === n && n % 1 !== 0) {
      return tableIndex.includes(i)
        ? tooltipFormatter(parseFloat(n.toFixed(2)), this.state.currencyData, true)
        : parseFloat(n.toFixed(2));
    } else if (String(n)) {
      return n;
    }
  };

  //set data to show and respectively set SKIP and LIMIT
  // dataToShowHandler = (newVal) => {
  //   let stateCopy = { ...this.state };

  //   let newPage;

  //   if (newVal === 100) {
  //     newPage = Math.trunc(
  //       stateCopy.page % 2 === 0 ? stateCopy.page / 2 : stateCopy.page / 2 + 1
  //     );
  //   } else {
  //     newPage = stateCopy.page * 2 - 1;
  //   }

  //   stateCopy.page = newPage;
  //   stateCopy.skip = stateCopy.page * newVal - newVal;
  //   stateCopy.limit = stateCopy.page * newVal;
  //   stateCopy.dataToShow = newVal;
  //   stateCopy.loader = true;
  //   this.setState(stateCopy);
  // };

  // change pagination page  respectively set SKIP and LIMIT
  // changeActivePage = (page) => {
  //   let state = { ...this.state };

  //   state.skip = page * state.dataToShow - state.dataToShow;
  //   state.limit = page * state.dataToShow;

  //   state.page = page;
  //   state.loader = true;
  //   this.setState(state);
  // };

  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = {...this.state};

    stateCopy.page = 1;
    stateCopy.skip = 0;
    stateCopy.limit = newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = {...this.state};

    state.skip = page - 1;
    state.limit = state.dataToShow;
    state.page = page;
    state.loader = true;
    this.setState(state);
  };

  tableDateRender = (date) => {
    let format = {
      Hour: 'll H:00',
      Day: 'll',
      Week: 'll',
      'Hour Of Day': 'HH:00',
      Year: 'll',
    };

    if (Object.keys(format).includes(this.state.selected.label)) {
      return moment(date).format(format[this.state.selected.label]);
    } else {
      return date;
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <div>
        <div className="containerDiv" ref={this.myRef}>
          <div className="">
            <div
              className="hoverPathClass globalFontSize"
              onClick={() => this.props.history.push('fanzly-analytics-overview')}
            >
              <i className="fas fa-angle-left mr-2"></i>Reports
            </div>
            <div className="title">Top Posts</div>
            <div className="d-flex text-grey mt-2" style={{marginBottom: ''}}>
              <div className="mr-3 db_ptr changeCurssor">
                <ReactToPrint
                  trigger={() => (
                    <span>
                      <i className="fas fa-print mr-1"></i>Print
                    </span>
                  )}
                  content={() => this.myRef.current}
                />
              </div>
              <div
                className="mr-3 db_ptr changeCurssor"
                onClick={() => this.setState({isExport: !this.state.isExport})}
              >
                <i className="fas fa-download mr-1"></i>Export
              </div>
            </div>
            <div
              className="moreThanThousand"
              style={{display: this.state.isMoreThanThousand ? '' : 'none'}}
            >
              <div className="iconDiv">
                <span className="iconSpan"></span>
              </div>
              <div className="bannerContent">
                This report shows up to 1,000 results. To see all results, you can
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <Filters
                // showCountry={false}
                handleDateChange={this.handleDateRange}
                groupByselectData={this.state.selectData}
                groupByselectHandler={this.selectHandler}
                groupByselected={this.state.selected}
              ></Filters>
              {/* </div> */}

              <div className="d-flex align-items-center justify-content-end mt-2 ml-auto">
                <div className="d-flex align-items-center globalFontSize">
                  Currency:
                  <div
                    style={{
                      marginLeft: '5px',
                      padding: '2px 18px 2px 12px',
                    }}
                    className="card"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={this.handleClick}
                  >
                    {this.state.currencyData}
                  </div>
                  <Menu
                    style={{
                      minHeight: '300px',
                      maxHeight: '300px',
                    }}
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                  >
                    <div className="currency-hover-class">
                      <MenuItem>
                        Currency:
                        <Input
                          id=""
                          className="filterCurrency"
                          {...this.state.currencyFilter.input}
                          onChange={(e) => this.filterCurrency(e)}
                        />
                      </MenuItem>
                      {this.state.filterData.map((k, index) => (
                        <MenuItem
                          key={index}
                          className={classes.menuItem}
                          onClick={() => this.getCurrencyData(k)}
                        >
                          <span>
                            <img src={k.flag} height={25} width={25} alt="currency" />
                          </span>
                          <span>^</span>
                          <span className="pl-3 currencySymbol">{k.currencies[0].symbol}</span>
                          <span className="pl-3">{k.name}</span>
                        </MenuItem>
                      ))}
                    </div>
                  </Menu>
                </div>

                <div className="d-flex justify-content-end align-items-center ml-3">
                  <span className="mr-2 globalFontSize">Source:</span>

                  <div className="mr-2 globalFontSize">
                    <ReactSelect
                      className="custom-z-index"
                      city={this.state.source}
                      change={this.sourceSelectHandler}
                      selected={this.state.selectedsource}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="activeBarGraph d-flex align-items-center justify-content-center"
            style={{ marginTop: "20px" }}
          >
            {this.state.loader ? (
              <h4 style={{ color: "grey" }}>Loading data...</h4>
            ) : this.state.dataLoaded ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <BarGraph config={this.state.graphData} />
              </div>
            ) : (
              <h4 style={{ color: "grey" }}>No data for selected date range</h4>
            )}
          </div> */}

          <div
            className="d-flex justify-content-center"
            style={{
              marginTop: '50px',
            }}
          >
            {this.state.loader ? (
              <div className="no-data-loader">
                <h4 style={{color: 'grey'}}>Loading data...</h4>
              </div>
            ) : this.state.dataLoaded ? (
              <TableComponent
                loader={this.state.loader}
                showTable={this.state.showTable}
                columns={this.state.columns}
                logsData={this.state.logsData}
                summary={this.state.summary}
                dateRender={(data) => this.tableDateRender(data)}
                returnDataWithTwoDecsOrInteger={(data, index) =>
                  this.returnDataWithTwoDecsOrInteger(data, index)
                }
                dataCount={this.state.dataCount}
                dataToShow={this.state.dataToShow}
                changeActivePage={this.changeActivePage}
                dataToShowHandler={this.dataToShowHandler}
              />
            ) : (
              <div className="no-data-loader">
                <h4 style={{color: 'grey'}}>No data for selected date range</h4>
              </div>
            )}
          </div>

          {/* Export Modal will only open when user click on export button then only this modal will to ask for export and cancel */}
          <Modal
            isOpen={this.state.isExport}
            toggle={() => this.setState({isExport: !this.state.isExport})}
            width="35%"
          >
            <div className="col-12 px-0">
              <div className="py-3 reportModal-header pl-3 border-bottom">
                Export your Table Data
              </div>
              <div className="py-3 reportModal-subText pl-3 border-bottom">
                Table will be exported as a CSV (comma separated values).
              </div>
              <div className="py-3 col-12">
                <div className="d-flex justify-content-end">
                  <Pbutton
                    onClick={() => this.setState({isExport: !this.state.isExport})}
                    className="reportModal-cancelBtn"
                  >
                    Cancel
                  </Pbutton>
                  <CSVLink
                    onClick={() => this.setState({isExport: !this.state.isExport})}
                    data={this.state.logsData}
                    filename="my-file.csv"
                    className="reportModal-exportBtn"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </Modal>
          <Snackbar open={this.state.showSnackbar} message={this.state.snackBarMessage} />
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(TopNPost));
